<template>
            <div class="background-form-group p-4" style="display: flex;vertical-align: middle;">
                <h1 class="pr-5">Analyze</h1>
                <div class="p-2 inputWhiteDiv form-row align-items-center">
                    <div class="green-border-focus col-auto">
                          <label class="my-1 mr-2" for="dateStart">Start Date</label>
                <input type="date" class="form-control" id="dateStart" v-model="dateStart"></div>   
                <div class="green-border-focus col-auto">
                      <label class="my-1 mr-2" for="dateEnd">End Date</label>
                <input type="date" class="form-control" id="dateEnd" v-model="dateEnd"></div>
                <div class="col-auto">
                <button type="button" class="btn btn-dark mb-6 px-1" @click="generateReport()">Search Dates</button>
            </div>
                    </div>
                    <p style="color:red" v-if="dateFinderError">{{dateFinderError}}</p>
            </div>
    <div class="align-items-center w-100 h-100 p-3" style="background: #e7e7e7;">
        <div class="background-form-group p-2 mb-2">
            <h3 style="font-weight:bold; color:white; margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    border-radius: 10px;
    padding: 15px; background:black;">Overview:</h3>
            <div class="inputWhiteDiv align-items-center">
                <div class="row w-100">
                    <div class="col-auto">
                        <div class="boxContent bg-primary align-items-center justify-content-center">
                            <div class="row">
                                <div class="col-auto" style="display:flex;width:100%">  
                                    <font-awesome-icon icon="cash-register" class="float-left" size="3x" />
                                    <h1 class="float-right" style="margin-right: auto;margin-left: auto;">Sales</h1>
                                </div>
                                 <div class="col-auto" style="display: grid; text-align: center;width:100%;">
                                        <h2 class="px-2 float-right">${{report_data?.summary?.sales ? report_data?.summary?.sales.toLocaleString() : 0}}</h2>
                                    <br>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="boxContent bg-success align-items-center justify-content-center">
                            <div class="row">
                                <div class="col-auto" style="display:flex;width:100%">
                                    <font-awesome-icon icon="user" class="float-left" size="3x" />
                                    <h1 class="float-right" style="margin-right: auto;margin-left: auto;">Labour</h1>
                                </div>
                                 <div class="col-auto" style="display: grid; text-align: center;width:100%;">
                                    <h2 class="px-2 float-right">${{report_data?.summary?.labour ? report_data?.summary?.labour.toLocaleString() : 0}}</h2>
                                    <h2 class="px-2 float-right">{{divider(report_data?.summary?.labour,report_data?.summary?.sales)}}</h2>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="boxContent  bg-warning justify-content-center align-items-center">
                          <div class="row">
                                <div class="col-auto" style="display:flex;width:100%">
                                    <font-awesome-icon icon="shopping-cart" class="float-left" size="3x" />
                                   <h1 class="float-right" style="margin-right: auto;margin-left: auto;">Purchases</h1>
                                </div>
                                 <div class="col-auto" style="display: grid; text-align: center;width:100%;">
                                    <h2 class="px-2">${{report_data?.summary?.purchases ? report_data?.summary?.purchases.toLocaleString() : 0}}</h2>
                                <h2 class="px-2 float-right">{{divider(report_data?.summary?.purchases,report_data?.summary?.sales)}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: auto;background:#dbdbdb;width: 6px;border-radius: 10px;" />
                       <div class="col-auto">
                        <div class="boxContent  bg-success justify-content-center align-items-center">
                             <h1>Budget</h1>
                          <div class="row">
                                 <div class="col-auto" style="display: grid; text-align: center;width:100%;">
                                    <h2 class="px-2 float-right">{{report_data?.summary?.budget ? report_data?.summary?.budget : 0}}%</h2>
                                <h2 class="px-2 float-right"></h2>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
              </div>

      
        </div>
                <div class="background-form-group p-2 mb-2">
            <h3 style="font-weight:bold; color:white; margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    border-radius: 10px;
    padding: 15px; background:black;">Labour By Employee:</h3>
            <div class="inputWhiteDiv align-items-center">
                <div class="w-100">
                    <table class="table bg-white">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Regular Hours</th>
                                <th scope="col">Overtime</th>
                                <th scope="col">Stat</th>
                                <th scope="col">Total Hours</th>
                                <th scope="col">Total Wage</th>
                                <th scope="col">Advance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(staff,index) in staffTotals">
                                <td>{{getStaffNameById(staff.id)}}</td>
                                <td>{{staff.hours}}</td>
                                <td>{{staff.ot}}</td>
                                <td>{{staff.stat}}</td>
                                <td>{{staff.hours_total}}</td>
                                <td>${{staff.pay}}</td>
                                <td>${{staff.dailyAdvance}}</td>
                            </tr>
                            <tr>
                                <td><b>Total</b></td>
                                <td><b>{{report_data.summary.hours}}</b></td>
                                <td><b>{{report_data.summary.hours_ot}}</b></td>
                                <td><b>{{report_data.summary.hours_stat}}</b></td>
                                <td><B>{{report_data.summary.hours_total}}</B></td>
                                <td><B>${{report_data.summary.total_wage}}</B></td>
                                <td><b>${{report_data.summary.dailyAdvance}}</b></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>

        </div>
        <div class="background-form-group p-2 mb-2">
            <h3 style="font-weight:bold; color:white; margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    border-radius: 10px;
    padding: 15px; background:black;">Daily Breakdown:</h3>
            <div class="inputWhiteDiv align-items-center">
                <div class="w-100">
                    <table class="table bg-white">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Sales</th>
                                <th scope="col">Purchases</th>
                                <th scope="col">Regular Pay</th>
                                <th scope="col">OT Pay</th>
                                <th scope="col">Stat Pay</th>
                                 <th scope="col">Total Wage</th>
                                <th scope="col">Advance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(day,index) in dayTotals">
                                <td>{{day.date}}</td>
                                <td>${{day.sales.toLocaleString()}}</td>
                                <td>${{day.purchases.toLocaleString()}}</td>
                                <td>${{day.pay.toLocaleString()}}</td>
                                <td>${{day.otPay.toLocaleString()}}</td>
                                <td>${{day.statPay.toLocaleString()}}</td>
                                <td>${{day.totalPay.toLocaleString()}}</td>
                                 <td>${{day.advances.toLocaleString()}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>

        </div>
      
        </div>

</template>
<script>
import UserService from '../services/user.service';

export default {
    name: 'reportViewer',
    data() {
        return {
            restaurantMeta: Object,
            dateFinderError:null,
            dateStart:null,
            dateEnd:null,
            staff_list:null,
            report_data: {
              summary:{
                sales:null,
                labour:null,
                purchases:null,
                budget:null,
              },
              staff:{},
              days:[]
            }
        };
    },
      computed: {
                currentUser() {
            return this.$store.state.auth.user;
        },
        frontStaff() {
            return this.report.staff_list.filter(function(el) {
                return el.position_id == 4 || el.position_id == 5 || el.position_id == 1
            })
        },
        backStaff() {
            return this.report.staff_list.filter(function(el) {
                return el.position_id == 2 || el.position_id == 3
            })
        },
        dateClean(){
            return this.report.date
        },
        staffTotals(){
            const listify = (obj, mapFn) =>
                  Object.entries(obj).reduce((acc, [key, value]) => {
                    acc.push(mapFn(key, value));
                            return acc;
                  }, []);   
            let staffArray = listify(this.report_data.staff, (key, value) => ({ id: key, values:value }));
            let returned = staffArray.map(staff=>{
                return {id:staff.id,
                    ot:staff.values.reduce((accum, item) => {
                        return accum + Number(item.ot)}, 0),
                pay:staff.values.reduce((accum, item) => {
                        return accum + Number(item.pay)}, 0),
                hours:staff.values.reduce((accum, item) => {
                        return accum + Number(item.hours)}, 0),
                stat:staff.values.reduce((accum, item) => {
                        return accum + Number(item.stat)}, 0),
                hours_total:staff.values.reduce((accum, item) => {
                        return accum + Number(item.hours_total)}, 0)}
            })
            return returned
        },
        dayTotals(){   
            console.log(this.report_data)
            let returned = this.report_data?.days?.map(day=>{
                return {
                id:day.id,
                date:day.report_date,
                sales:(day?.meta_daily?.sales?.liqour + day?.meta_daily?.sales?.food),
                purchases: day?.meta_daily?.purchases?.reduce((accum, item) => {
                        return accum + (Number(item.cost))}, 0),
                otPay:day?.meta_daily?.staff_list?.reduce((accum, item) => {
                        return accum + (Number(item.dailyOT)*1.5*item.wage)}, 0),
                pay:day?.meta_daily?.staff_list?.reduce((accum, item) => {
                        return accum + (Number(item.dailyHours)*item.wage)}, 0),
                statPay:day?.meta_daily?.staff_list?.reduce((accum, item) => {
                        return accum + (Number(item.dailyStat)*(1.5)*item.wage)}, 0),
                totalPay:day?.meta_daily?.staff_list?.reduce((accum, item) => {
                        return accum + ((Number(item.dailyOT)*1.5*item.wage)+(Number(item.dailyStat)*(1.5)*item.wage)+(Number(item.dailyHours)*item.wage))}, 0),
                advances:day?.meta_daily?.staff_list?.reduce((accum, item) => {
                        return (accum + (item.dailyAdvance))}, 0)
                }
            })
            return returned
        }
    },
    methods:{
      divider(div,dividor){
        let val = Math.round(div*100 / dividor*100)/100
        return Number.isNaN(val) ? "" : `${val}%`
      },
      getStaffNameById(id){
        let staff = this.staff_list.find(staff=>staff.id==parseInt(id))
        return staff ? `${staff.f_name ? staff.f_name :""} ${staff.l_name ? staff.f_name : ""}`: ''
      },
        idToPosition(id) {
            if (this.positions.length > 0) {

                return this.positions.filter(function(el) {
                    return el.id == id
                })[0].name
            } else {
                return ''
            }
        },
        idToRestaurant(id) {
            if (this.restaurants.length > 0) {
                return this.restaurants.filter(function(el) {
                    return el.id == id
                })[0].name
            } else {
                return ''
            }
        },
      generateReport(){
        if(this.dateStart == null || this.dateStart == undefined || this.dateEnd == null || this.dateEnd == undefined){
                this.dateFinderError = "Please Select a Date."
            }
            else{
                  this.dateFinderError = null
                  
                UserService.getAnalyzeData({dateStart:this.dateStart,dateEnd:this.dateEnd}).then(
            response => {
                var staffHours = {}
                let summary = response.data[0]
                summary.forEach(day=>{
                    day.meta_daily.staff_list.forEach(staffEntry=>{
                        if (staffHours.hasOwnProperty(staffEntry.id)){
                            staffHours[staffEntry.id].push({date:day.report_date,dailyAdvance:Number(staffEntry.dailyAdvance),pay:(Number(staffEntry.dailyHours) * Number(staffEntry.wage) + Number(staffEntry.dailyOT) * Number(staffEntry.wage) * 1.5 + Number(staffEntry.dailyStat) * Number(staffEntry.wage) * 1.5),hours:staffEntry.dailyHours,ot:staffEntry.dailyOT,stat:staffEntry.dailyStat,hours_total:(Number(staffEntry.dailyHours)+ Number(staffEntry.dailyOT) + Number(staffEntry.dailyStat))})
                        }
                        else {
                            staffHours[staffEntry.id] = [{date:day.report_date,dailyAdvance:Number(staffEntry.dailyAdvance),pay:(Number(staffEntry.dailyHours) * Number(staffEntry.wage) + Number(staffEntry.dailyOT) * Number(staffEntry.wage) * 1.5 + Number(staffEntry.dailyStat) * Number(staffEntry.wage) * 1.5),hours:staffEntry.dailyHours,ot:staffEntry.dailyOT,stat:staffEntry.dailyStat,hours_total:(Number(staffEntry.dailyHours)+ Number(staffEntry.dailyOT) + Number(staffEntry.dailyStat))}]
                        }
                    });
                });
                    let summary_labour = summary.map(day =>{
                      return day.meta_daily.staff_list.reduce((accum, item) => accum + (Number(item.dailyHours) * Number(item.wage) + Number(item.dailyOT) * Number(item.wage) * 1.5 + Number(item.dailyStat) * Number(item.wage) * 1.5), 0)
                    }).reduce((accum, item) => accum + Number(item), 0)
                let summary_sales = summary.reduce((accum, item) => accum + (Number(item.meta_daily.sales.food) + Number(item.meta_daily.sales.liqour)), 0);
                let summary_purchases = summary.map(day =>{
                  return day.meta_daily.purchases.reduce((accum, item) => accum + Number(item.cost), 0)
                }).reduce((accum, item) => accum + Number(item), 0);
                let summary_budget = (((summary_purchases/summary_sales) - parseFloat(this.restaurantMeta.budget_food)) + ((summary_labour/summary_sales) - parseFloat(this.restaurantMeta.budget_labour))) * 100
                // labour summaries
                let summary_hours = summary.map(day =>{
                      return day.meta_daily.staff_list.reduce((accum, item) => accum + (Number(item.dailyHours)), 0)
                    }).reduce((accum, item) => accum + Number(item), 0)
                let summary_ot = summary.map(day =>{
                      return day.meta_daily.staff_list.reduce((accum, item) => accum + (Number(item.dailyOT)), 0)
                    }).reduce((accum, item) => accum + Number(item), 0)
                let summary_stat = summary.map(day =>{
                      return day.meta_daily.staff_list.reduce((accum, item) => accum + (Number(item.dailyStat)), 0)
                    }).reduce((accum, item) => accum + Number(item), 0)
                let summary_totalHours = summary.map(day =>{
                      return day.meta_daily.staff_list.reduce((accum, item) => accum + (Number(item.dailyStat) + Number(item.dailyOT) + Number(item.dailyHours)), 0)
                    }).reduce((accum, item) => accum + Number(item), 0)
                let total_advance = summary.map(day =>{
                      return day.meta_daily.staff_list.reduce((accum, item) => accum + (Number(item.dailyAdvance)), 0)
                    }).reduce((accum, item) => accum + Number(item), 0)
                let total_wage = summary.map(day =>{
                  return day.meta_daily.staff_list.reduce((accum, item) => accum + (Number(item.dailyHours) * Number(item.wage) + Number(item.dailyOT) * Number(item.wage) * 1.5 + Number(item.dailyStat) * Number(item.wage) * 1.5), 0)
                }).reduce((accum, item) => accum + Number(item), 0)

                let summary_tot = {
                    labour: summary_labour,
                    sales: summary_sales,
                    purchases: summary_purchases, 
                    budget:parseInt(summary_budget),
                    hours:summary_hours,
                    hours_total:summary_totalHours,
                    hours_ot:summary_ot,
                    hours_stat:summary_stat,
                    total_wage:total_wage,
                    dailyAdvance:total_advance

                  }
                this.report_data = {
                  summary:summary_tot,
                  staff: staffHours,
                  days: summary
              };
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
            }
      }
    },
        mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        UserService.getStaffList().then(
            response => {
                this.staff_list = response.data;

            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        UserService.getRestaurantMeta().then(
            response =>{
                this.restaurantMeta = response?.data?.meta_restaurant
            });
    }
};
</script>
<style scoped>
.boxContent {
    padding: 10px;
    height: 200px;
    width: 275px;
    display: grid;
    border-radius: 10px;
    color: white;
    text-align:center;
}

.background-form-group {
    background: #fff;
    border-radius: 8px;
}
</style>